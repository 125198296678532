<!--
 * @Author: 段海兵
 * @Date: 2022-11-09 16:09:22
 * @LastEditors: 段海兵
 * @Description: filecontent
 * @email: duanhb@bayconnect.com.cn
-->
<template>
  <div class="screen">
    <!-- 图片先请求缓存下来 -->
    <img v-for="(n, i) in '1234567890'" :key="`n-${i}`" style="display: none;" :src="require(`@/assets/images/screen/${n}.png`)">
    <div class="screen-title">
      <div class="screen-title-logo" @click="goHome">{{ title }}</div>
      <div class="screen-title-content t-c">竞价大厅</div>
      <div class="screen-title-time">当前时间：{{ systemTime.format('yyyy-MM-dd hh:mm:ss') }}</div>
    </div>
    <div class="screen-body">
      <div class="screen-body-timer border-container">
        <div class="label t-c">{{ countDown.label }}</div>
        <div class="value flex x-center y-end cl-yellow">
          <span v-if="countDown.status === 4">竞价结束</span>
          <div v-else class="flex">
            <div v-for="(n, i) in getNumber(countDown.h, 3)" :key="`h-${i}`" :class="`time-item num-${n}`" />
            <div class="time-item hour" />
            <div v-for="(n, i) in getNumber(countDown.m)" :key="`m-${i}`" :class="`time-item num-${n}`" />
            <div class="time-item minute" />
            <div v-for="(n, i) in getNumber(countDown.s)" :key="`s-${i}`" :class="`time-item num-${n}`" />
            <div class="time-item second" />
          </div>
        </div>
      </div>
      <div class="screen-body-info border-container">
        <div class="screen-body-info-title t-c font-medium">标的信息</div>
        <div class="screen-body-info-content flex space-between">
          <div class="screen-body-info-items">
            <div class="screen-body-info-item">
              <div class="label">产品代码</div>
              <div class="value cl-yellow">{{ bidInfo.fundcode }}</div>
            </div>
            <div class="screen-body-info-item">
              <div class="label">成交方式</div>
              <div class="value">单轮竞价</div>
            </div>
          </div>
          <div class="screen-body-info-items">
            <div class="screen-body-info-item">
              <div class="label">产品名称</div>
              <div class="value cl-yellow">{{ bidInfo.fundname }}</div>
            </div>
            <div class="screen-body-info-item">
              <div class="label">发放总量</div>
              <div class="value">{{ bidInfo.applicationvol | fixNum }}吨</div>
            </div>
          </div>
          <div class="screen-body-info-items">
            <div class="screen-body-info-item">
              <div class="label">买卖方向</div>
              <div class="value">竞价买入</div>
            </div>
            <div class="screen-body-info-item">
              <div class="label">竞价底价</div>
              <div class="value">{{ bidInfo.reserveprice | fixNum(2) }}元/吨</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { renderWebSetting } from '@/setting'
const setting = renderWebSetting()
import { getApi } from '@/utils/request'
import { toDate, getUrlParams } from '@/utils'
import { mapState } from 'vuex'
export default {
  name: 'Screen',
  data() {
    return {
      title: setting.title,
      // 标的信息
      bidInfo: {}
    }
  },
  computed: mapState({
    // 服务器时间
    systemTime: state => state.system.time,
    // 倒计时
    countDown() {
      if (this.bidInfo.lotid && ['01', '02', '06', '07'].includes(this.bidInfo.applystatus)) {
        const systemTime = this.systemTime.getTime()
        const startSignTime = toDate(this.bidInfo.enterstartdate + this.bidInfo.enterstarttime).getTime()
        const endSignTime = toDate(this.bidInfo.enterenddate + this.bidInfo.enterendtime).getTime()
        const startBidTime = toDate(this.bidInfo.bidstartdate + this.bidInfo.bidstarttime).getTime()
        const endBidTime = toDate(this.bidInfo.bidenddate + this.bidInfo.bidendtime).getTime()
        let time = 0
        let status = 0
        let label = ''
        if (startSignTime > systemTime) {
          // 报名未开始
          time = startSignTime - systemTime
          status = 0
          label = '距离报名开始还剩'
        } else if (systemTime < endSignTime) {
          // 报名中
          time = endSignTime - systemTime
          status = 1
          label = '距离报名结束还剩'
        } else if (startBidTime > systemTime) {
          // 竞价未开始
          time = startBidTime - systemTime
          status = 2
          label = '距离竞价开始还剩'
        } else if (systemTime < endBidTime) {
          // 竞价中
          time = endBidTime - systemTime
          status = 3
          label = '距离竞价结束还剩'
        } else {
          // 竞价结束
          time = 0
          status = 4
          label = '竞价状态'
        }
        const h = parseInt(time / (60 * 60 * 1000))
        const m = parseInt((time % (60 * 60 * 1000)) / (60 * 1000))
        const s = parseInt((time % (60 * 1000)) / 1000)
        return {
          h,
          m,
          s,
          status,
          label
        }
      }
      return {
        h: 0,
        m: 0,
        s: 0,
        status: 4,
        label: '竞价状态'
      }
    }
  }),
  created() {
    this.getBidInfo({
      lotid: getUrlParams('lotid') || ''
    })
  },
  methods: {
    getNumber(n, fix = 2) {
      const num = `0000000000000000${n}`
      return num.substring(num.length - fix, num.length)
    },
    // 获取标的信息
    getBidInfo(params = {}) {
      getApi('kingdom.kfts.client_get_lot_info_bj', 'V1.0', { ...params })
        .then((kdjson) => {
          const flag = kdjson.flag
          const items = kdjson.items
          if (flag === '1' && items) {
            this.bidInfo = items[0]
          } else if (flag === '1') {
            this.bidInfo = {}
          } else {
            this.bidInfo = {}
            this.$message({
              message: kdjson.msg || '未获取到标的信息！',
              type: 'error'
            })
          }
        })
        .catch((kdjson) => {
          this.bidInfo = {}
          this.$message({
            message: kdjson.msg,
            type: 'error'
          })
        })
    },
    goHome() {
      this.$router.replace({
        name: 'Home'
      })
    }
  }

}
</script>
<style scoped lang="scss">
  .screen {
    width: 100%;
    height: 100vh;
    color: #FFFFFF;
    background-image: url("~@/assets/images/screen/bg.png");
    background-size: cover;
    &-title {
      height: 6.25rem;
      border-bottom: 1px solid #29934A;
      position: relative;
      line-height: 6.25rem;
      &-logo {
        font-size: 1.5rem;
        position: absolute;
        left: 3.5rem;
        top: 0;
        cursor: pointer;
      }
      &-content {
        font-size: 2.125rem;
      }
      &-time {
        font-size: 1.125rem;
        position: absolute;
        right: 3.5rem;
        top: 0;
      }
    }
    &-body {
      height: calc( 100% - 6.25rem);
      padding: 1.5rem 2rem;

      .border-container {
        border: .125rem solid #29934A;
        background-image: radial-gradient(circle at 50% 50%, rgba(6, 46, 17, .2), rgba(9, 96, 23, .6));
        border-radius: .5rem;
      }

      &-timer {
        height: 21.875rem;
        margin-bottom: 1.5rem;
        padding: 3.5rem 0;
        .label {
          font-size: 2rem;
          color: #F0FFE7;
          line-height: 3rem;
        }
        .value {
          height: 11rem;
          font-size: 5rem;
          line-height: 11rem;

          .time-item {
            width: 7.5rem;
            height: 8.875rem;
            background-size: cover;
            &.num-0 {
              background-image: url("~@/assets/images/screen/0.png");
            }
            &.num-1 {
              background-image: url("~@/assets/images/screen/1.png");
            }
            &.num-2 {
              background-image: url("~@/assets/images/screen/2.png");
            }
            &.num-3 {
              background-image: url("~@/assets/images/screen/3.png");
            }
            &.num-4 {
              background-image: url("~@/assets/images/screen/4.png");
            }
            &.num-5 {
              background-image: url("~@/assets/images/screen/5.png");
            }
            &.num-6 {
              background-image: url("~@/assets/images/screen/6.png");
            }
            &.num-7 {
              background-image: url("~@/assets/images/screen/7.png");
            }
            &.num-8 {
              background-image: url("~@/assets/images/screen/8.png");
            }
            &.num-9 {
              background-image: url("~@/assets/images/screen/9.png");
            }
            &.day {
              width: 5.125rem;
              background-image: url("~@/assets/images/screen/d.png");
            }
            &.hour {
              width: 5.125rem;
              background-image: url("~@/assets/images/screen/h.png");
            }
            &.minute {
              width: 5.125rem;
              background-image: url("~@/assets/images/screen/m.png");
            }
            &.second {
              width: 5.125rem;
              background-image: url("~@/assets/images/screen/s.png");
            }
          }
        }
      }

      &-info {
        height: calc(100% - 23.375rem);
        &-title {
          font-size: 2rem;
          line-height: 6.25rem;
        }
        &-content {
          padding: 0 15rem;
        }
        &-item {
          color: #E9F5FD;
          margin-bottom: 2rem;
          &:last-child {
            margin-bottom: 0;
          }
          .label {
            font-size: 1.5rem;
            line-height: 2.625rem;
            color: #F0FFE7;
          }
          .value {
            font-size: 1.75rem;
            line-height: 4.25rem;
            font-weight: bold;
          }
        }
      }
    }
  }

</style>
